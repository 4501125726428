import { useEffect, useState } from "react";
import axios from '../../config/axios';

//importing components
import Layout from "../../Components/Layout/Layout"
import { Pagination, Input, Button, Select, Checkbox } from "antd";
import Loader from "../../Components/Library/Loader/Loader";
import UserItem from "../../Components/UserItem";
import { Row, Col, Form } from 'antd';
import { SiMicrosoftexcel } from 'react-icons/si';

const Users = () => {

    const [limit, setLimit] = useState(20);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [dataCount, setDataCount] = useState(0);
    const [form] = Form.useForm();
    const [filters, setFilters] = useState({});
    const [refresh, setRefresh] = useState(null);

    useEffect(() => {
        setLoading(true);
        console.log(filters)
        window.scrollTo(0, 0);
        axios.get('/admin/users', {
            withCredentials: true, params: {
                page: page,
                limit: limit,
                // name: filters.name ? filters.name : null,
                phone_number: filters.phone_number ? filters.phone_number : null,
                azima_id: filters.azima_id ? filters.azima_id : null,
                // status: filters.status ? filters.status : null,
                // nuovo_del_eligible: filters.nuovo_del_eligible ? true : null
            }
        }).then(response => {
            setUsers(response.data.results);
            setDataCount(response.data.count);
            setLoading(false);
        })
    }, [page, filters, limit, refresh]);


    //defining applyFilter function
    const applyFilter = (values) => {
        setFilters(values);
    }

    return (
        <Layout>
            <h1 className="title">User Management</h1>
            <div className="card">
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <span className="card__title">All Users</span>
                    <a href="/excel/user" target={'_blank'} rel='noreferrer' style={{ display: 'flex', alignItems: 'center' }}>
                        <SiMicrosoftexcel size={20} style={{ marginRight: '.5rem' }} />
                        <span style={{ color: 'var(--color-primary)', cursor: 'pointer' }} className="card__title">Export To Excel</span>
                    </a>
                </div>

                <div className="filter">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={applyFilter}
                    >
                        <Row gutter={[16, 16]}>
                            {/* <Col sm={4}>
                                <Form.Item label="Name" name={'name'}>
                                    <Input placeholder="Filter by name" />
                                </Form.Item>
                            </Col> */}
                            <Col sm={4}>
                                <Form.Item label="Azima ID" name={'azima_id'}>
                                    <Input placeholder="Search by azima id" />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item label="Phone Number" name={'phone_number'}>
                                    <Input placeholder="Filter by phone number" />
                                </Form.Item>
                            </Col>
                            {/* <Col sm={4}>
                                <Form.Item label="Status" name={'status'}>
                                    <Select
                                        placeholder="Filter by Status"
                                    >
                                        <Select.Option value={'incomplete'}>Incomplete</Select.Option>
                                        <Select.Option value={'pending verification'}>Pending Verification</Select.Option>
                                        <Select.Option value={'verified'}>Verified</Select.Option>
                                        <Select.Option value={'blocked'}>Blocked</Select.Option>
                                        <Select.Option value={'temporary blocked'}>Temporary Blocked</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item valuePropName="checked" label=" " name={'nuovo_del_eligible'}>
                                    <Checkbox>Enrolled Users With No Loans</Checkbox>
                                </Form.Item>
                            </Col> */}
                            <Col sm={4} style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Form.Item label="Actions">
                                    <Button htmlType="submit" type="primary" style={{ flex: '0 0 48%', marginRight: '.5rem' }}>Apply</Button>
                                    <Button onClick={() => {
                                        form.resetFields();
                                        setFilters({});
                                    }
                                    } type="danger" style={{ flex: '0 0 48%' }}>Clear</Button>
                                </Form.Item>
                            </Col>
                        </Row>


                    </Form>
                    <Row>
                        <Col sm={4}></Col>
                    </Row>
                </div>

                {
                    loading ?
                        <Loader height={'75vh'} />
                        :
                        <>
                            <div className="table__wrapper">
                                <table className="table">
                                    <thead>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>DP</th>
                                        <th>Phone Number</th>
                                        <th>Status</th>
                                        <th>Balance</th>
                                        <th>Deposit</th>
                                        <th>Interest</th>
                                        <th>Date of Reg</th>
                                    </thead>
                                    <tbody>
                                        {
                                            users.map((user, index) => {
                                                return <UserItem key={index} user={user} setRefresh={setRefresh} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                <span>Showing {((page - 1) * limit) + 1} to {((page - 1) * limit) + limit > dataCount ? dataCount : ((page - 1) * limit) + limit} of {dataCount} entries</span>
                                <Pagination
                                    pageSize={limit}
                                    total={dataCount}
                                    current={page}
                                    onChange={value => setPage(value)}
                                    onShowSizeChange={(curr, size) => setLimit(size)}
                                />
                            </div>
                        </>
                }

            </div>
        </Layout>
    );
}

export default Users;