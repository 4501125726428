import { message } from 'antd';
import { useState } from 'react';
import axios from '../config/axios';
import Loader from './Library/Loader/Loader';


const ImageUploader = ({ setter }) => {

    const [loading, setLoading] = useState(false);

    //defining onChangeHandler function
    const onChangeHandler = (e) => {
        setLoading(true);
        let formData = new FormData;
        formData.append('file', e.target.files[0]);

        axios.post('/users/file-upload', formData, { withCredentials: true }).then(response => {
            setter(response.data.secure_url);
        }).catch(error => {
            message.error('A server side error occurred');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div style={{ marginTop: '1rem' }}>
            <input type="file" onChange={onChangeHandler} />
            {
                loading &&
                <Loader />
            }
        </div>
    );
};

export default ImageUploader;