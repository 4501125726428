import axios from '../../config/axios';
import moment from '../../config/moment';
import { Link } from 'react-router-dom';

//importing context
import { AuthContext } from '../../Contexts/AuthContext';

//importing components
import { Tag, Select, message } from "antd";
import { useContext } from 'react';
import { formattedAmount, getUserData } from '../../utils/misc';


const DepositsTable = ({ transactions }) => {

    const { user } = useContext(AuthContext);

    //defining changeTransactionStatus function
    const changeTransactionStatus = (trxID, value) => {
        let payload = {
            status: value,
            type: 'deposit'
        }

        axios.patch(`/transactions/admin/change-status/${trxID}`, payload, { withCredentials: true }).then(response => {
            message.success('Transaction status updated!');
        }).catch(error => {
            message.error(error.response.data.msg);
        })
    }
    return (
        <table className="table">
            <thead>
                <th>ID</th>
                <th>User</th>
                <th>Amount</th>
                <th>TRX Fee</th>
                <th>Gateway ID</th>
                <th>Status</th>
                <th>Timestamp</th>
            </thead>
            <tbody>
                {
                    transactions.map((trx, index) => {
                        return <tr key={index}>
                            <td>{trx.id}</td>
                            <td>
                                <Link to={`/users/${trx.user.id}`} target={'_blank'}>
                                    <a href="" style={{textTransform: 'capitalize'}}>
                                        {getUserData(trx.user.info).first_name} {getUserData(trx.user.info).last_name}
                                        <br/>
                                        {getUserData(trx.user.info).phone_number}
                                    </a>
                                </Link>
                            </td>
                            <td>{formattedAmount(trx.amount)} UGX</td>
                            <td>{formattedAmount(trx.trx_fee)} UGX</td>
                            <td style={{ fontSize: '1.15rem' }}>
                                Req ID: {trx.beyonic_trx_id ? trx.beyonic_trx_id : "N/A"}
                                <br />
                                Coll ID: {trx.beyonic_collection_id ? trx.beyonic_collection_id : "N/A"}
                            </td>

                            <td>
                                {
                                    getUserData(user.info).is_super_admin ?
                                        <Select
                                            defaultValue={trx.status}
                                            onChange={value => changeTransactionStatus(trx.id, value)}
                                        >
                                            <Select.Option value={'pending'}>Pending</Select.Option>
                                            <Select.Option value={'success'}>Success</Select.Option>
                                            <Select.Option value={'failed'}>Failed</Select.Option>
                                        </Select>
                                        :
                                        <Tag color={trx.status === 'pending' ? 'var(--color-warning)' : trx.status === 'success' ? 'var(--color-primary)' : 'var(--color-red)'} style={{ textTransform: 'capitalize' }}>{trx.status}</Tag>
                                }

                            </td>
                            <td>
                                {moment(trx.created_at).format('MMMM D, YYYY - LT')}
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>
    )
}

export default DepositsTable;
