import { Button } from "antd";
import React, { useEffect, useState } from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import Loader from "../../Components/Library/Loader/Loader";
import axios from '../../config/axios';
import moment from '../../config/moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;




const UserExcel = (props) => {
    const [loading, setLoading] = useState(true);
    const [dataset, setDataset] = useState([]);


    useEffect(() => {
        setLoading(true);
        axios.get('/admin/users?limit=5000', { withCredentials: true }).then(response => {
            setDataset(response.data.results);
            setLoading(false);
        });
    }, []);



    return (
        <>
            {
                loading ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column' }}>
                        <Loader />
                        <br />
                        <span>Generating Excel File...</span>
                    </div>
                    :
                    <ExcelFile
                        element={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                                <Button type="primary">Download Excel</Button>
                            </div>
                        }
                        filename={"azima-users-" + moment(new Date()).format('MMMM-DD-YY-LT')}
                    >
                        <ExcelSheet data={dataset} name={'Users'}>
                            <ExcelColumn label="ID" value="id" />
                            <ExcelColumn label="First Name" value="first_name" />
                            <ExcelColumn label="Last Name" value="last_name" />
                            <ExcelColumn label="Phone Number" value="phone_number" />
                            <ExcelColumn label="Email" value="email" />
                            <ExcelColumn label="Date of Birth" value="date_of_birth" />
                            <ExcelColumn label="Date of Registration" value="created_at" />
                            <ExcelColumn label="Sex" value="sex" />
                            <ExcelColumn label="District" value="district" />
                            <ExcelColumn label="Town" value="town" />
                            <ExcelColumn label="NID Number" value="nin" />
                            <ExcelColumn label="Status" value="status" />
                            <ExcelColumn label="Current Loan Limit" value="loan_limit" />
                            <ExcelColumn label="Nuovo Device ID" value="device_id" />
                        </ExcelSheet>
                    </ExcelFile>
            }
        </>
    )
}

export default UserExcel;