import styles from './Loader.module.scss';

const Loader = ({ height }) => {
    return (
        <div className={styles.loader} style={{ height: height ? height : '10vh' }}>
            <img src="/icon.png" alt="" className={styles.icon} />
        </div>
    );
}

export default Loader;